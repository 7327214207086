import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import media from 'styled-media-query'
import { useToasts } from 'react-toast-notifications'
import ContactForm from 'components/contactForm'
import { getFaktorToken } from '../../services/admin'

// display faktor prospect area webview
function ProspectArea(props) {
  const location = useLocation()
  const [queryString, setQueryString] = useState('')
  const [faktorUri, setFaktorUri] = useState('')
  const [heightIframe, setHeightIframe] = useState('')
  const { addToast } = useToasts()
  //const rows = window.innerWidth <= 812 ? 6 : 12;

  //retrieve page variable from current route
  const { match } = props
  const pageParam = match.params?.page ? match.params.page : 'signin'

  useEffect(() => {
    // retrieve query string from current route
    const searchParams = new URLSearchParams(location.search)
    const params = []
    searchParams.forEach((value, key) => {
      params.push(`${key}=${value}`)
    })
    setQueryString(params.join('&'))

    //get faktor token from arlet backend
    getFaktorToken()
      .then(dt => {
        setFaktorUri(
          `${process.env.REACT_APP_PROSPECT_AREA_BASE_PATH}/prospect-area/${
            process.env.REACT_APP_PUBLISHER_REFERENCE
          }/${pageParam}?app_type=AppWeb&jwt=${dt}${
            queryString !== '' ? '&' + queryString : ''
          }`
        )
      })
      .catch(e =>
        addToast(
          "Il semblerait qu'il y ait un problème avec la recuperation de données: " +
            e.message,
          {
            appearance: 'error',
            autoDismiss: true
          }
        )
      )

    window.addEventListener(
      'message',
      function(e) {
        var eventName = e.data[0]
        var data = e.data[1]
        switch (eventName) {
          case 'setHeight':
            setHeightIframe(data + 25)
            break
        }
      },
      false
    )
  }, [location.search, addToast, pageParam, queryString])

  return (
    <Container>
      <IframeContainer>
        <iframe
          title={`espace-client`}
          src={faktorUri}
          frameBorder="0"
          scrolling="no"
          allow="allow"
          allowFullScreen="allowFullScreen"
          style={{
            width: '100%',
            height: `${heightIframe}px`,
            minHeight: 'calc(100vh - 80px)'
          }}
        />
      </IframeContainer>
    </Container>
  )
}

const Center = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`

const TopSection = styled.div`
  padding-top: 60px;
  background: linear-gradient(0deg, #ffffff 0%, #f3fbfd 85.23%);
  text-align: center;
  padding-bottom: 200px;

  ${media.lessThan('1025px')`
    padding: 30px 0px 150px 0px;
  `}
`

const Title = styled.div`
  font-size: 39px;
  font-weight: 800;
  line-height: 0.87;
  padding-bottom: 30px;
  color: #1787e0;

  ${media.lessThan('1025px')`
    padding-bottom: 26px;
    font-size: 22px;
  `}
`

const FormContainer = styled(ContactForm)`
  flex-wrap: wrap;
  flex-direction: row;
  height: auto;
  width: 100%;
  max-width: 600px;
`
const Container = styled.div`
  min-height: calc(100vh - 80px);
  align-items: stretch;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Intro = styled.div`
  width: 80%;
  margin: auto;
  font-size: 1.1em;
  font-weight: 600;
  line-height: 1.37;
  padding: 30px;
  color: #114b62;
  max-width: 500px;
  padding: 0 20px;

  ${media.lessThan('1025px')`
    width: 100%;
    font-size: 1.1em;
    font-weight: 600;

  `}
`
const IframeContainer = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  margin: auto;
  flex-grow: 1;
`
const Form = styled.div`
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  margin: 30px 0px;
  min-height: calc(100vh - 261px);

  ${media.lessThan('1025px')`
    width: 100%;
    margin-top: 0px;
    border-radius: 19px 19px 0px 0px;
    font-size: 16px;
  `}
`

export default ProspectArea
