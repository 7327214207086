import React, { Fragment } from 'react'
import get from 'lodash/get'
//import find from 'lodash/find'
import { formatNumber } from 'utils/string'
//import { round2Decimal } from 'utils/math'
import { getActiveSpec } from 'utils/hit'
// import styled from 'styled-components'
// import media from 'styled-media-query'

import Section from './Section'
import SectionName from './SectionName'
import Text from './Text'
import styled from 'styled-components'
import media from 'styled-media-query'
// not a page but component to display price as a location way
function RentalPrice({ hit }) {
  if (!hit) {
    return null
  }
  const specs = getActiveSpec(hit)
  // const specs = get(hit, 'specs[0]')
  const tariff_type_2 = get(hit.tariffs, 'PriceExcludingTax')
  const ttcprice = hit.ttc_tariff
  const hcprice = tariff_type_2 ? tariff_type_2.price : 0
  if (!ttcprice && !hcprice) {
    return <Text>Informations non communiquées.</Text>
  }
  const rent_charge_cost_1 =
    get(specs, 'periodic_costs.rent_charge.type') === 1 &&
    get(specs, 'periodic_costs.rent_charge.cost')
  const rent_charge_cost_2 =
    get(specs, 'periodic_costs.rent_charge.type') === 2 &&
    get(specs, 'periodic_costs.rent_charge.cost')
  const rent_charge_cost_3 = get(specs, 'periodic_costs.rent_charge.type') === 3
  const rent_supplement_cost = get(specs, 'periodic_costs.rent_supplement.cost')

  const fee_buyer = get(specs, 'fixed_costs.fees.buyer')
  const fixed_cost_assessment = get(specs, 'fixed_costs.assesment')
  const fixed_cost_security_deposit = get(specs, 'fixed_costs.security_deposit')
  const fees_scale_ref = get(specs, 'fixed_costs.fees.scale_ref')

  const co_owner_data_lot_amount = get(hit, 'details.co_owner.data.lot_amount')
  const share_charge = get(specs, 'periodic_costs.share_charge.cost')
  const co_owner_data_save_plan = get(hit, 'details.co_owner.data.save_plan')

  return (
    <Fragment>
      <Text>
        Loyer :{' '}
        {ttcprice
          ? `${formatNumber(ttcprice)} €/mois charges comprises`
          : `${formatNumber(hcprice)} €/mois hors charges`}
        <br />
        <br />
        {!!rent_charge_cost_1 ||
        !!rent_charge_cost_2 ||
        !!rent_charge_cost_3 ||
        !!rent_supplement_cost ? (
          <Fragment>
            Détail des charges :<br />
          </Fragment>
        ) : (
          ''
        )}
        {rent_charge_cost_1 ? (
          <Fragment>
            Provision pour charges : {rent_charge_cost_1} €<br />
          </Fragment>
        ) : (
          ''
        )}
        {rent_charge_cost_2 ? (
          <Fragment>
            Charges forfaitaires : {rent_charge_cost_2} €<br />
          </Fragment>
        ) : (
          ''
        )}
        {rent_charge_cost_3 ? (
          <Fragment>
            Remboursement annuel des charges par le locataire sur justificatifs
            <br />
          </Fragment>
        ) : (
          ''
        )}
        {rent_supplement_cost ? (
          <Fragment>
            Complément de loyer : {rent_supplement_cost}
            <br />
          </Fragment>
        ) : (
          ''
        )}
      </Text>

      {fee_buyer > 0 && (
        <Text>
          Honoraires TTC à la charge du locataire : {fee_buyer} €<br />
          {fixed_cost_assessment ? (
            <Fragment>
              - dont réalisation de l'état de lieux : ${fixed_cost_assessment} €
              <br />
            </Fragment>
          ) : (
            ''
          )}
          {fixed_cost_security_deposit
            ? `Dépôt de garantie : ${fixed_cost_security_deposit} €`
            : ''}
        </Text>
      )}
      {!!fees_scale_ref && fee_buyer > 0 && (
        <ViewSection href={fees_scale_ref} target="blank">
          {/* <Fontello color={theme.colors.primary} size={15} name="document" /> */}
          <ContactImage
            resizeMode={'contain'}
            resizeMethod="resize"
            src={require('assets/hitDetail/icn-bareme.png')}
          />
          <TextBareme>Barème des honoraires</TextBareme>
        </ViewSection>
      )}
      {co_owner_data_lot_amount >= 2 && share_charge > 0 && (
        <Text>
          A propos de la copropriété :<br />
          La copropriété inclut {co_owner_data_lot_amount} lots pour un montant
          annuel de la quote part du budget prévisionnel des dépenses courantes
          de {share_charge} €.
          {co_owner_data_save_plan ? (
            <Fragment>
              <br />
              La copropriété fait l'objet d'une procédure en cours.
            </Fragment>
          ) : (
            ''
          )}
        </Text>
      )}
    </Fragment>
  )
}

function SellPrice({ hit }) {
  if (!hit) {
    return null
  }

  const ttcprice = hit.ttc_tariff
  if (!ttcprice) {
    return <Text>Information non communiquée</Text>
  }

  const specs = get(hit, 'specs[0]')
  const tariff_type_2 = get(hit.tariffs, 'PriceExcludingTax')
  const hcprice = tariff_type_2 ? tariff_type_2.price : 0
  const fee_buyer = get(specs, 'fixed_costs.fees.buyer')
  const fixed_cost_fees_type_1 = get(specs, 'fixed_costs.fees.type') === 1
  const fixed_cost_fees_type_2 = get(specs, 'fixed_costs.fees.type') === 2
  const fixed_cost_fees_type_3 = get(specs, 'fixed_costs.fees.type') === 3
  const fixed_costs_fees_buyer_percent = get(
    specs,
    'fixed_costs.fees.buyer_percent'
  )
  const co_owner_data_lot_amount = get(hit, 'details.co_owner.data.lot_amount')
  const share_charge = get(specs, 'periodic_costs.share_charge.cost')
  const co_owner_data_save_plan = get(hit, 'details.co_owner.data.save_plan')
  const periodic_costs_tax_property_cost = get(
    specs,
    'periodic_costs.tax_property.cost'
  )
  const fees_scale_ref = get(specs, 'fixed_costs.fees.scale_ref')

  let spaceSubContent = ''
  if (
    ttcprice &&
    (fixed_cost_fees_type_1 ||
      fixed_cost_fees_type_2 ||
      (fixed_cost_fees_type_3 && fee_buyer))
  ) {
    spaceSubContent = (
      <Fragment>
        <br />
        <br />
      </Fragment>
    )
  } else if (
    fees_scale_ref ||
    (co_owner_data_lot_amount >= 2 && share_charge > 0) ||
    periodic_costs_tax_property_cost
  ) {
    spaceSubContent = <br />
  }

  return (
    <Fragment>
      <Text>
        Prix du bien : {formatNumber(ttcprice)} €{spaceSubContent}
        {ttcprice && fixed_cost_fees_type_1 ? (
          <Fragment>
            Honoraires TTC inclus à la charge de l'acquéreur :{' '}
            {fixed_costs_fees_buyer_percent.toFixed(1)} % du bien hors
            honoraires.
            <br />
            Prix du bien hors honoraires : {formatNumber(hcprice)} €
            <br />
          </Fragment>
        ) : (
          ''
        )}
        {ttcprice && fixed_cost_fees_type_2 ? (
          <Fragment>
            Les honoraires sont à la charge du vendeur <br />
          </Fragment>
        ) : (
          ''
        )}
        {ttcprice && fixed_cost_fees_type_3 && fee_buyer ? (
          <Fragment>
            Les honoraires sont à la charge de l'acquéreur :{' '}
            {fixed_costs_fees_buyer_percent.toFixed(1)} % du bien hors
            honoraires.
            <br />
            Prix du bien hors honoraires charges acquéreur :{' '}
            {formatNumber(ttcprice - fee_buyer)} €<br />
            Des honoraires sont également à la charge du vendeur
            <br />
          </Fragment>
        ) : (
          ''
        )}
      </Text>
      {!!fees_scale_ref && (
        <ViewSection href={fees_scale_ref} target="blank">
          {/* <Fontello color={theme.colors.primary} size={15} name="document" /> */}
          <ContactImage
            resizeMode={'contain'}
            resizeMethod="resize"
            src={require('assets/hitDetail/icn-bareme.png')}
          />
          <TextBareme>Barème des honoraires</TextBareme>
        </ViewSection>
      )}
      {!!(co_owner_data_lot_amount >= 2 && share_charge > 0) && (
        <Text>
          A propos de la copropriété : <br />
          La copropriété inclut {co_owner_data_lot_amount} lots pour un montant
          annuel de la quote part du budget prévisionnel des dépenses courantes
          de {share_charge} €.
          {co_owner_data_save_plan ? (
            <Fragment>
              <br />
              La copropriété fait l'objet d'une procédure en cours.
            </Fragment>
          ) : (
            ''
          )}
        </Text>
      )}
      {!!periodic_costs_tax_property_cost && (
        <Text>
          <br />
          <br />
          Montant indicatif de la taxe foncière :{' '}
          {periodic_costs_tax_property_cost} €
        </Text>
      )}
    </Fragment>
  )
}

function AboutPrice({ data }) {
  return (
    <Section>
      <SectionName>À propos du prix</SectionName>

      {data.category === 1 ? (
        <SellPrice hit={data} />
      ) : (
        <RentalPrice hit={data} />
      )}
    </Section>
  )
}

const ViewSection = styled.a``

const TextBareme = styled.span`
  font-size: 1em;
  color: #1787e0;
  font-style: normal;
  font-weight: 700;

  ${media.lessThan('1025px')`
    font-size: 1em;
  `}
`

const ContactImage = styled.img`
  width: 20px;
  margin-right: 15px;
  margin-top: 5px;
`

export default AboutPrice
