export const capitalizeFirstLetterOnly = title =>
  title ? title.charAt(0).toUpperCase() + title.slice(1).toLowerCase() : ''

/* capitalize first letter but dont lowercase the rest */
export const capitalizeFirstLetter = title =>
  title ? title.charAt(0).toUpperCase() + title.slice(1) : ''

export const capitalizeEachWord = text =>
  text
    .toLowerCase()
    .split(' ')
    .map(s => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ')

// add thousand separator of a number
export const formatNumber = num => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

export const numberTwoDigits = num => {
  return num > 0 && num < 10 ? `0${num}` : num
}
