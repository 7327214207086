import React from 'react'
import styled, { keyframes } from 'styled-components/macro'
import media from 'styled-media-query'
import { Animated } from 'react-animated-css'
//import theme from 'utils/theme'
//import close from 'assets/broadcast/close-blue.svg'
import BG from 'assets/neuf/header2.jpg'
import BGMobile from 'assets/neuf/header2.jpg'
import Section1 from './Section1'
import Section2 from './Section2'
import Section3 from './Section3'

// not display new real estates, tell user to download app to see them
function Neuf() {
  return (
    <div>
      <Hero>
        <TuilesSlider>
          <TitleContainer>
            <Title>Trouvez le logement neuf de vos rêves avec Arlet</Title>
            <SubTitle>
              Retrouvez dans l’application tous nos programmes neufs sur toute
              la France.
            </SubTitle>
            <Cta href="#">Télécharger Arlet</Cta>
          </TitleContainer>
        </TuilesSlider>
        <Section1 />
        <Section2 />
        <Section3 />
      </Hero>
    </div>
  )
}

const Hero = styled.div`
  width: 100%;
  ${media.lessThan('1025px')`
  width: 100%;
`}
`
const Cta = styled.a`
  display: flex;
  position: relative;
  top: 30px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  z-index: 998;
  width: 100%;
  max-width: 300px;
  height: 50px;
  background-color: #e84c5c;
  color: #ffffff;
  padding: 0 20px;
  font-weight: 700;
  margin: auto;
  text-align: center;
  cursor: pointer;
  border-radius: 40px;
  margin: 0 auto;
`
/*
const Overlay = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: rgba(0, 0, 0, 0.6);
  z-index: 999;
  opacity: 1;
  height: 100vh;
  ${media.greaterThan('1025px')`
    display: none;
  `}
  &.show {
    visibility: hidden !important;
  }
  &.hide {
    visibility: visible !important;
    position: fixed;
    top: 0;
  }
`
*/
const TuilesSlider = styled.div`
  height: 70vh;
  //background: rgba(5, 163, 180, 1);
  background: #ffffff;

  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0 -16px 17px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 -16px 17px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 -16px 17px rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 0 -16px 17px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0 -16px 17px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  transform: translateZ(0);
`

const TitleContainer = styled.div`
  width: 100vw;
  background-image: url(${BG});
  background-size: cover;
  height: 100%;
  position: absolute;
  align-content: center;
  align-self: center;
  justify-content: center;
  justify-self: center;
  background-position: bottom;
  display: flex;

  flex-direction: column;
  ${media.lessThan('1025px')`
    padding: 5px;
    width: calc(100vw - 10px);
    background-image: url(${BGMobile});
  `}
`

const Title = styled.h1`
  font-size: 3em;
  font-weight: 900;
  line-height: 1;
  /* text-shadow: 0 2px 20px rgba(26, 67, 98, 0.59); */
  color: #ffffff;
  /*background: rgba(255, 255, 255, 0.5); */
  padding: 20px;
  width: 100vw;
  text-align: center;
  align-content: center;
  align-self: center;
  justify-content: center;
  justify-self: center;
  opacity: 0.9;
  position: relative;
  width: calc(86% - 454px);
  text-align: center;
  margin: 0 auto;
  max-width: 630px;

  ${media.lessThan('1025px')`
    font-size: 1.5em;
    line-height: 1em;
    width: calc(100% - 40px);
    text-align: center;
    left: 0%;
    top: 30vh;
    margin: 0 auto;
  `}

  /* < iPhone X */
  @media (max-width: 1025px) and (max-height: 812px) {
    top: 15vh;
  }

  /* < iPhone 6 */
  @media (max-width: 1025px) and (max-height: 620px) {
    top: 5vh;
  }

  ${media.greaterThan('1025px')`
  `}
`

const SubTitle = styled.h2`
  font-size: 1em;
  font-weight: 600;
  line-height: 1;
  /* text-shadow: 0 2px 20px rgba(26, 67, 98, 0.59); */
  color: #ffffff;
  /*background: rgba(255,255,255,.5);*/
  padding: 20px;
  width: 100vw;
  text-align: center;
  align-content: center;
  align-self: center;
  justify-content: center;
  justify-self: center;
  opacity: .9;
  position: relative;
  width: calc(86% - 454px);
  text-align: center;
  max-width: 630px;
  margin: 0 auto;
  }
  ${media.lessThan('1025px')`
    font-size: 1.1em;
    line-height: 1.2em;
    padding-top: 0;
    width: calc(100% - 1em);
    text-align: center;
    left: 0%;
    margin: auto;
    margin-bottom: 15px;
    text-align: center;
    padding: 2em .5em;
    top: 30vh;
  `}

  /* < iPhone X */
  @media (max-width: 1025px) and (max-height: 812px) {
      top: 15vh;
   }

    /* < iPhone 6 */
    @media (max-width: 1025px) and (max-height: 620px) {
        top: 2vh;
     }

`
/*
const Blue = styled.span`
  color: ${theme.colors.primary};
`
*/
export default Neuf
