import React from 'react'
import styled from 'styled-components/macro'
import media from 'styled-media-query'
//import BannerBG from 'assets/home/bg_banner_arlet.jpg'
//import logo from 'assets/logo/icon-app-arlet.png'
//import AndroidBadge from 'components/androidBadge'
//import IosBadge from 'components/iosBadge'
import TestimonialBg from 'assets/home/bg-testimonials.png'
import Testimonial from 'components/testimonial'
import SectionTitle from 'components/sectionTitle'
//import theme from 'utils/theme'

// section of index page
function Section7() {
  return (
    <Section>
      <Center>
        <Title top margin="0px auto 40px auto">
          Ils aiment Arlet et le font savoir
        </Title>
        <Img loading="lazy" src={TestimonialBg} />
        <Container>
          <Testimonial name="EnguerrandSavy" stars="5">
            “Recherche innovante et fluide ! Je ne reçois quel es annonces qui
            m'intéressent :)”
          </Testimonial>

          <Testimonial name="ambermlt" stars="5">
            “Je suis en pleine recherche immobilièr eet je n'ai enfin plus
            besoin d'aller sur 4 ou 5 applications pour trouver des biens qui
            m'intéressent? Design élégant et épuré. Je vous la conseille !”
          </Testimonial>

          <Testimonial name="Ksé91" stars="5">
            “Création de mon alerte immobilière très simplement sur
            l'application Arlet! Tous les jours, je découvre de nombreuses
            offres en adéquation avec ma recherche immobilière. Je recommande
            cette application !”
          </Testimonial>

          <Testimonial name="rod85" stars="5">
            “appli extremement bien faite, fluide et qui me faciulite bien la
            vie dans la recherche de mon nouvel appartement ! Je recommande !”
          </Testimonial>

          <Testimonial name="Joäo Gomez" stars="5">
            “Application simple, épurée, facile à maramétrer et efficace. J'ai
            trouvé mon bien grâce à elle !!”
          </Testimonial>

          <Testimonial name="fnhont" stars="5">
            “Application sympa et tres utile pour trouver vite !”
          </Testimonial>
        </Container>
      </Center>
    </Section>
  )
}

const Title = styled(SectionTitle)`
  min-width: 430px;
  width: 100%;
  position: absolute;
  margin: ${props => (props.margin ? props.margin : '30px auto 40px auto')};

  ${media.lessThan('468px')`
    top: 41vw;
    margin: 20px auto 40px auto !important;
  `}

  ${media.between('468px', '768px')`
    top: 45vw !important;
    `}

  ${media.lessThan('1170px')`
    min-width: 260px;
    margin: 0px auto 40px auto;
    width: 90%;
  `}

    ${media.between('768px', '1170px')`
      top: 33vw;
      margin: 0px auto 40px auto !important;
      `}

    ${media.greaterThan('1170px')`
      top: calc(100vw / 2.47 / 1.5);
    `}

`

const Section = styled.div`
  margin: 40px 0px;
  overflow: hidden;
  ${media.lessThan('1170px')`
    margin: 40px 0px;
  `}

  ${media.lessThan('768px')`
    margin-bottom: 12em;
  `}

  ${media.greaterThan('1170px')`
      margin: 40px 0 0 0;
  `}

`

const Center = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
/*
const Text = styled.div`
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  font-size: 1em;
  line-height: 1.17;
  text-align: center;
  max-width: 545px;
  margin-bottom: 10px;
  ${media.lessThan('1170px')`
    font-size: 21px;
  `}
`
*/
const Container = styled.div`
  display: flex;
  text-align: center;
  flex-wrap: wrap;
  flex-direction: row;
  max-width: 1200px;
  position: absolute;
  top: 45em;

  ${media.lessThan('768px')`
    position: relative;
    top: 0em;
    margin-top: -2em;
    max-width: 550px;
  `}

  ${media.between('767px', '768px')`
    position: relative;
    top: 0em;
    right: -50%;
    margin: auto;
    justify-content: center;
    transform: translateX(-50%);
    margin-bottom: -11em;
  `}

  ${media.between('769px', '1170px')`
    position: relative;
    top: -3em;
    right: -50%;
    margin: auto;
    justify-content: center;
    transform: translateX(-50%);
    margin-bottom: -11em;
  `}

  ${media.greaterThan('1170px')`
    position: relative;
    top: -4em;
    margin: auto;
    justify-content: center;
    max-width:100%;
    max-width: 1000px;
  `}

`

const Img = styled.img`

  ${media.lessThan('768px')`
      width: 150%;
      margin-bottom: -8em;
    `}
    ${media.between('768px', '1170px')`
      width: 133%;
    `}
    ${media.between('1000px', '1170px')`
      width: 123%;
    `}


  ${media.greaterThan('1170px')`
    width: 100%;
      `}


`

export default Section7
