import { firstOptions, secondOptions } from 'interface/properties'
import get from 'lodash/get'
import uniqBy from 'lodash/uniqBy'
import { capitalizeEachWord } from './string'

// extract main caracteristics of program
const getProgramOptions = program => {
  const options = [{ icon: 'search_ou', label: program.location.postal_code }]
  const { ads } = program.details.program
  if (ads.length) {
    const minArea = Math.min(
      ...ads.map((ad: ProgramProperty) => ad.details.area)
    )
    const maxArea = Math.max(
      ...ads.map((ad: ProgramProperty) => ad.details.area)
    )
    const minRooms = Math.min(
      ...ads.map((ad: ProgramProperty) => ad.details.rooms.total)
    )
    const maxRooms = Math.max(
      ...ads.map((ad: ProgramProperty) => ad.details.rooms.total)
    )
    const minBedrooms = Math.min(
      ...ads.map((ad: ProgramProperty) => ad.details.rooms.bedrooms)
    )
    const maxBedrooms = Math.max(
      ...ads.map((ad: ProgramProperty) => ad.details.rooms.bedrooms)
    )
    if (minArea) {
      options.push({
        icon: 'am_surface',
        label:
          minArea === maxArea ? `${minArea} m²` : `${minArea}-${maxArea} m²`
      })
    }
    if (minRooms) {
      options.push({
        icon: 'am_pieces',
        label:
          minRooms == maxRooms
            ? `${minRooms} ${minRooms > 1 ? ' pièces' : ' pièce'}`
            : `${minRooms}-${maxRooms} pièces`
      })
    }
    options.push({
      icon: 'maison',
      label: ads.length != 1 ? `${ads.length} appart.` : '1 appart.'
    })
    if (minBedrooms) {
      options.push({
        icon: 'am_chambres',
        label:
          minBedrooms == maxBedrooms
            ? `${minBedrooms} ${minBedrooms > 1 ? ' chambres' : ' chambre'}`
            : `${minBedrooms}-${maxBedrooms} chambres`
      })
    }
  }

  if (program.details.delivery_date && program.details.delivery_date > 1) {
    const date = new Date(program.details.delivery_date)
    const nTrimester = Math.floor(date.getMonth() / 3) + 1
    options.push({
      label:
        (nTrimester == 1 ? '1er' : `${nTrimester}e`) +
        ` trimestre ${date.getFullYear()}`,
      icon: 'chrono'
    })
  }

  return options
}
// get options of a program [{icon:"", label: ""}]
export const getProgramDetail = program => {
  const details = []

  const importantProgramOptions = getProgramOptions(program)
  for (const opt of importantProgramOptions) {
    details.push({
      icon: opt.icon,
      label: opt.label
    })
  }

  const allOptions = [...firstOptions, ...secondOptions]
  const programOptions = [...new Set(program.details.options)]

  programOptions.forEach(option => {
    const optionExist = allOptions.find(opt =>
      opt.value.split(' OR ').includes('' + option)
    )

    if (optionExist && optionExist.icon) {
      details.push({
        label: optionExist.label,
        icon: optionExist.icon
      })
    }
  })

  return uniqBy(details, 'label')
}

const importantHitOptions = Object.freeze([
  { key: 'location.postal_code', icon: 'search_ou', label: '[value]' },
  { key: 'details.area', icon: 'am_surface', label: '[value] m²' },
  { key: 'details.floor', icon: 'am_floor', label: '[value] étage(s)' },
  { key: 'details.rooms.total', icon: 'am_pieces', label: '[value] pièce(s)' },
  {
    key: 'details.rooms.bedrooms',
    icon: 'am_chambres',
    label: '[value] chambre(s)'
  }
])
// get options of any hit [{icon:"", label: ""}]
export const getHitDetail = hit => {
  const details = []

  for (const opt of importantHitOptions) {
    const value = get(hit, opt.key)
    if (!value) {
      continue
    }
    details.push({
      icon: opt.icon,
      label: opt.label.replace(`[value]`, value)
    })
  }

  const allOptions = [...firstOptions, ...secondOptions]
  const hitOptions = [...new Set(hit.details.options)]

  hitOptions.forEach(option => {
    const optionExist = allOptions.find(opt =>
      opt.value.split(' OR ').includes('' + option)
    )

    if (optionExist && optionExist.icon) {
      details.push({
        label: optionExist.label,
        icon: optionExist.icon
      })
    }
  })

  return uniqBy(details, 'label')
}
// real estate ads (kanal search ads api), has a spec (specificities of a real estate not shared by all providers)
// capture and return all spec of providers active (not deleted)
export const getActiveSpec = hit => {
  if (!hit || !hit.specs || !hit.specs.length) {
    return undefined
  }

  let has_expired_spec = false
  let has_valid_not_scrapped_spec = false
  for (const spec of hit.specs) {
    if (spec.status === 4) {
      has_expired_spec = true
    }
    if (spec.status === 1 && !spec.customs?.redirect) {
      has_valid_not_scrapped_spec = true
    }
  }

  if (has_expired_spec && !has_valid_not_scrapped_spec) {
    return undefined
  }

  /* keep only specs with status === 1 (active) */
  const specs = hit.specs.filter(i => i.status === 1)

  specs.sort((a, b) => {
    /* we prioritize without redirect first, then price min */
    try {
      if (!!a?.customs?.redirect && !b?.customs?.redirect) {
        return 1
      }

      if (!!b?.customs?.redirect && !a?.customs?.redirect) {
        return -1
      }

      if (
        new Date(a.timestamps.updated_at) < new Date(b.timestamps.updated_at)
      ) {
        return 1
      }

      if (
        new Date(a.timestamps.updated_at) > new Date(b.timestamps.updated_at)
      ) {
        return -1
      }
    } catch (err) {
      return 0
    }
    return 0
  })

  return specs[0]
}
// return url of a redirectable real estate
export const getRedirectFromSpec = spec => {
  const url = get(spec, 'customs.redirect')
  if (!url) {
    return { url: null, name: null }
  }

  return { url, name: getRedirectName(url) }
}

const getRedirectName = url => {
  try {
    url = url
      .replace('https://', '')
      .replace('http://', '')
      .replace('www.', '')
    const name = url
      .split('.')[0]
      .replace('-', ' ')
      .replace('_', ' ')

    return capitalizeEachWord(name)
  } catch (err) {
    console.warn(err)
    return null
  }
}
