import React, { useContext } from 'react'
import styled from 'styled-components/macro'
import { withRouter, NavLink } from 'react-router-dom'
import media from 'styled-media-query'
import { Animated } from 'react-animated-css'
import { observer } from 'mobx-react-lite'

import close from 'assets/header/close.svg'
//import logoM3 from 'assets/header/Logo-arlet@3x.png'
//import logoM2 from 'assets/header/Logo-arlet@2x.png'
import logo from 'assets/header/Logo-arlet.png'
//import logoD3 from 'assets/header/Logo-arlet@3x.png'
//import logoD2 from 'assets/header/Logo-arlet@2x.png'
//import logoD from 'assets/header/Logo-arlet.png'
import IosBadge from 'components/iosBadge'
import AndroidBadge from 'components/androidBadge'
import UiStoreContext from 'stores/UiStore'

//import Headroom from 'react-headroom'
/*
 * component for menu
 */
const MobileMenu = observer(({ location: { pathname } }, { initialShow }) => {
  const broadcastheader = pathname === '/broadcast' && window.innerWidth <= 1300
  const { showMobileMenu, setProperty } = useContext(UiStoreContext)

  const closeMenu = () => {
    document.body.classList.remove('lock-body')
    setProperty('showMobileMenu', false)
  }

  if (initialShow) return null

  return (
    <SAnimated broadcastheader={broadcastheader}>
      <Animated
        animationIn="fadeIn"
        animationOut="fadeOut"
        isVisible={showMobileMenu}
        animationInDuration={600}
        animationOutDuration={600}
      >
        <Overlay />

        <Menu broadcastheader={broadcastheader}>
          <TopSection>
            <Animated
              animationIn="fadeInDown"
              animationOut="fadeOutUp"
              isVisible={showMobileMenu}
              animationInDelay={300}
              animationInDuration={600}
              animationOutDuration={600}
            >
              <Logo src={logo} alt="Arlet logo" />
            </Animated>

            <Close src={close} onClick={() => closeMenu()} alt="close" />
          </TopSection>

          <Animated
            animationIn="fadeIn"
            animationOut="fadeOut"
            isVisible={showMobileMenu}
            animationInDelay={300}
            animationInDuration={600}
            animationOutDuration={600}
          >
            <Links>
              <MenuOption>
                <NavLink to="/" onClick={() => closeMenu()}>
                  Accueil
                </NavLink>
              </MenuOption>

              <MenuOption>
                <NavLink to="/broadcast" onClick={() => closeMenu()}>
                  Diffuser sur Arlet
                </NavLink>
              </MenuOption>

              <MenuOption>
                <NavLink to="/contact" onClick={() => closeMenu()}>
                  Contactez-nous
                </NavLink>
              </MenuOption>

              {process.env?.REACT_APP_PROSPECT_AREA_SHOW === 'true' && (
                <MenuOption>
                  <NavLink to="/espace-client" onClick={() => closeMenu()}>
                    Espace Client
                  </NavLink>
                </MenuOption>
              )}
            </Links>
          </Animated>

          <Animated
            animationIn="fadeInUp"
            animationOut="fadeOut"
            isVisible={showMobileMenu}
            animationInDelay={300}
            animationInDuration={600}
            animationOutDuration={600}
          >
            <BottomSection>
              <IosBadge />
              <AndroidBadge />
            </BottomSection>
          </Animated>
        </Menu>
      </Animated>
    </SAnimated>
  )
})

const Overlay = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-image: linear-gradient(to top, #1564a2, #1787e0);
  z-index: 2000;
  opacity: 1;
  height: 100vh;

  ${media.greaterThan('1300px')`
    display: none;
  `}
`

const Menu = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0px 26px 0px;
  z-index: 2100;
  ${media.greaterThan('1300px')`
    display: none;
  `}

  ${media.greaterThan('1025px')`
    width: ${props =>
      props.broadcastheader
        ? 'calc(100% - 454px) !important'
        : '100% !important'};
  `}
`

const TopSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
`

const Logo = styled.img`
  width: auto;
  height: 55px;
}
`

const Close = styled.img`
  position: absolute;
  right: 25px;
  width: 25px;
  height: 25px;
  object-fit: contain;
`

const Links = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

const MenuOption = styled.span`
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: bold;
  &:last-child {
    margin-bottom: 0px;
  }
`

const BottomSection = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 10px;
`

const SAnimated = styled.div`
  z-index: 2000;
`

export default withRouter(MobileMenu)
