import React from 'react'
import styled, { css } from 'styled-components/macro'
import media from 'styled-media-query'
import theme from 'utils/theme'

/*
 * add a centered div with title inside
 */
function SectionTitle(props) {
  return (
    <Center {...props}>
      <Title margin="auto auto">{props.children}</Title>
    </Center>
  )
}

const Center = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: auto 10px;
  margin: ${props => (props.margin ? props.margin : '30px auto 30px auto')};

  ${props =>
    props.padding &&
    css`
      padding: 0 5% 30px 5%;
    `};
`

const Title = styled.div`
  position: relative;
  font-size: 1.8em;
  font-weight: 800;
  text-align: center;
  color: ${theme.colors.secondary};
  z-index: 0;
  height: auto;
  margin:  ${props => props.margin};

  ${media.lessThan('768px')`
    font-size: 1.4em;
    line-height: 1.2;
    padding: auto 20px;
  `}


  ${media.lessThan('768px')`
        margin: ${props =>
          props.margin ? props.margin : '0px auto 10px auto;'};
    `}
    ${media.between('768px', '1170px')`
        margin: ${props =>
          props.margin ? props.margin : '10px auto 10px auto;'};

      `}
  ${media.greaterThan('1170px')`
    margin: 10px auto 20px auto;
      `}

`

export default SectionTitle
