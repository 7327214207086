import React from 'react'
import styled from 'styled-components/macro'
import media from 'styled-media-query'

import SectionName from './SectionName'
import Text from './Text'
import { getTenantImage, getOwnerImage } from 'utils/image'
import { getActiveSpec } from 'utils/hit'
import get from 'lodash/get'
import theme from 'utils/theme'

// display agency contact of a real estate
function InfoContact({ hit, program }) {
  const specs = getActiveSpec(hit)
  const image = program ? getTenantImage(hit) : getOwnerImage(hit)
  // const specs = get(hit, 'specs[0]')
  const { tenant, owner } = specs
  // on remplace - par un espace

  // var name;
  //
  // !("identity" in owner) ? name = 'N/C' : name =  owner.identity.name;
  // !("identity" in tenant) ? name = 'N/C' : name =  tenant.identity.name;
  // !("identity" in entity) ? name = 'N/C' : name =  entity.identity.name;

  const name = program
    ? get(tenant, 'identity.name') || get(owner, 'identity.name')
    : get(owner, 'identity.name') || get(tenant, 'identity.name') || 'N/C'

  name
    .toString()
    .replace(/-/g, ' ')
    .replace(/^\./, '')

  const street = get(owner, 'location.street') || get(tenant, 'location.street')
  const postalCode =
    get(owner, 'location.postal_code') || get(tenant, 'location.postal_code')
  const city = get(owner, 'location.city') || get(tenant, 'location.city')

  if (!street && !name && !city && !postalCode) {
    return <Text grey>Informations non disponibles</Text>
  }
  return (
    <Container>
      {!program && <SectionName>Informations de contact</SectionName>}

      {image ? (
        <ContactImage
          resizeMode={'contain'}
          resizeMethod="resize"
          src={image}
        />
      ) : (
        <ImageDefault src={require('assets/hitDetail/noContact.png')} />
      )}
      {!!name && <ContactName>{name.toLowerCase()}</ContactName>}
      {!!street && <Address>{street}</Address>}
      <Address>
        {`
        ${!!postalCode ? postalCode.replace(/^\s+|\s+$|\s+(?=\s)/g, '') : ''}${
          postalCode && city ? ',' : ''
        } ${!!city ? city.toUpperCase() : ''}
        `}
      </Address>
    </Container>
  )
}

const Container = styled.div`
  margin-top: 50px;
`

const ContactImage = styled.img`
  display: block;
  width: auto;
  max-height: 68px;
  margin: 8px 0 15px 0;

  ${media.lessThan('1440px')`
  margin: 40px auto 25px auto;
  text-align: center;
  `};
`

const ImageDefault = styled.img`
  display: block;
  width: 68px;
  height: 68px;
  margin: 8px 0 15px 0;

  ${media.lessThan('1440px')`
      margin: 8px auto 15px auto;
      text-align: center;
    `};
`

const ContactName = styled.div`
  color: ${theme.colors.secondary};
  font-weight: 700;
  font-size: 1em;
  text-transform: uppercase;
  text-align: left;
  margin: 8px 0 15px 0;

  ${media.lessThan('1440px')`
    margin: 5px auto 5px auto;
    text-align: center;
  `};
`

const Address = styled.div`
  color: ${theme.colors.secondary};
  font-weight: 500;
  text-align: left;
  margin: 8px 0 15px 0;

  ${media.lessThan('1440px')`
    margin: 5px auto 5px auto;
    text-align: center;
  `};
`

export default InfoContact
