import React, { useEffect } from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'

// called by path /merci
function ThankYou() {
  useEffect(() => {
    //scroll to top of page
    window.scrollTo(0, 0)

    // //cookieBot text
    // const script = document.createElement('script')
    // script.src = `https://consent.cookiebot.com/a106756d-52bc-4b0e-b1aa-2cbaa2b30476/cd.js`
    // script.id = 'CookieDeclaration'
    // script.async = true
    // document.querySelector('#cookies').appendChild(script)
  }, [])

  return (
    <SPage id="top">
      <Container>
        <Illu src="/images/success.png" alt="Merci" />
        <Title>Merci pour votre confiance !</Title>
        <Text>
          Verisure vous contactera sous peu pour vous faire découvrir comment
          protéger votre maison des cambriolages 24h/24.
        </Text>
      </Container>
    </SPage>
  )
}
const SPage = styled.div`
  display: flex;
  justify-content: center;
  padding: 40px 0px;
`

const Container = styled.div`
  width: 80%;
  max-width: 800px;
  border-radius: 25px;
  box-shadow: 0 2px 87px 0 rgba(85, 163, 181, 0.1);
  background-color: #ffffff;
  margin: 0 auto;
  padding: 40px 40px 60px 40px;

  ${media.lessThan('1026px')`
          width: 100%;
          padding: 20px;
          box-shadow: none;
        `}
`

const Illu = styled.img`
  max-width: 90px;
  margin: auto;
  padding: 20px 40px;
  width: 100%;
  display: block;

  ${media.lessThan('1026px')`

        `}
`

const Text = styled.p`
  font-family: NunitoSans;
  font-size: 1em;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: #114b62;
  text-align: center;
  max-width: 500px;
  margin: auto;

  & > a,
  a:visited {
    text-decoration: underline !important;
    color: #05a3b4;
  }
`

const Title = styled.h1`
  font-family: NunitoSans;
  font-size: 2em;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: #114b62;
  text-align: center;
  font-weight: bold;

  & > a,
  a:visited {
    text-decoration: underline !important;
    color: #05a3b4;
  }
`

export default ThankYou
