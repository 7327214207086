import React from 'react'
import styled from 'styled-components/macro'
import media from 'styled-media-query'

// Component as a badge
function BadgeBlock({ width, height, img, srcSet, alt, title, text, list }) {
  return (
    <SBlock>
      <Center>
        <Img
          loading="lazy"
          width={width}
          height={height}
          src={img}
          srcSet={srcSet}
          alt={alt}
        />
      </Center>

      <Center className={title === undefined ? 'notitle' : ''}>
        <Title>{title}</Title>
      </Center>

      <Center>
        {text === undefined ? (
          <Text align="center">
            {list.map(textList => (
              <div key={`${textList}`}>{textList}</div>
            ))}
          </Text>
        ) : (
          <Text align="center" className={title === undefined ? 'bold' : ''}>
            {text}
          </Text>
        )}
      </Center>
    </SBlock>
  )
}

const SBlock = styled.div`
  padding: 10px 20px;
  flex: 1;
  min-width: 200px;
  max-width: 220px;

  ${media.lessThan('1025px')`
  margin: 0px ​10px 20px !important;
  `}

  ${media.lessThan('767px')`
    width: 100%;
    max-width: 250px;
    padding: 20px;
    margin: 0px ​10px 20px;
    &img{
        width: 70px;
        height: 70px;
    }
  `}

  ${media.between('767px', '1025px')`
  padding: 20px;
  `}

  ${media.greaterThan('1025px')`
  margin: 20px 10px 20px;
  `}

`

const Center = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  &.notitle {
    display: none;
  }
`

const Img = styled.img`
  width: ${props => (props.width ? props.width : '100%')};
  height: ${props => (props.height ? props.height : 'auto')};
  object-fit: contain;
`

const Title = styled.div`
  font-size: 1.2em;
  font-weight: 800;
  color: #114b62;
  margin-bottom: 30px;
  margin: 15px auto;

  ${media.lessThan('1025px')`
    margin-bottom: 10px;
    font-size: 1.2em;
  `}
`

const Text = styled.div`
  font-size: 0.9em;
  color: #114b62;
  text-align: center;
  ${media.lessThan('1025px')`
  font-weight: 500;
  text-align: ${props => props.align};
  `}
  &.bold {
    font-weight: 700;
  }
`

export default BadgeBlock
