import React from 'react'
import styled from 'styled-components/macro'
import media from 'styled-media-query'
import theme from 'utils/theme'
import Icn from 'assets/home/icn-organisez.svg'
import Organise from 'assets/home/organise.png'

// section of index page
function Section4() {
  return (
    <Start>
      <Img loading="lazy" src={Organise} />
      <Block>
        <SectionIcn src={Icn} />
        <Title>
          <Blue>Organisez</Blue> vos annonces comme vous le souhaitez
        </Title>
        <Text>
          Vous aimez cette annonce ? Ajoutez-là à vos Favoris. Une annonce ne
          correspond pas à ce que vous recherchez ? Masquez-là en un instant.
          <br />
          <br />
          Créez des listes pour organiser vos recherches et partagez facilement
          vos listes et vos annonces avec vos proches.
        </Text>
      </Block>
    </Start>
  )
}
const Start = styled.div`
  position: relative;
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 1200px;
  margin: auto;
  padding: 60px 0;

  ${media.lessThan('1170px')`
    flex-direction: column-reverse;
    align-items: center;
    width: 90%;
  `}

  ${media.lessThan('1170px')`
    padding: 50px 0;
  `}
`

const Block = styled.div`
  position: relative;
  max-width: 360px;
  width: auto;
  padding-top: 100px;
  padding-right: 10%;
  flex: 2;

  ${media.lessThan('768px')`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto 5%;
    padding: 0;
  `}
  ${media.between('768px', '1170px')`
    max-width: 550px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto 5%;
    padding: 0;
  `}
`

const Title = styled.div`
  font-size: 1.8em;
  font-weight: 800;
  color: ${theme.colors.secondary};
  margin-bottom: 30px;
  line-height: 1.1em;

  ${media.lessThan('768px')`
    font-size: 1.4em;
    line-height: 22px;
    margin: 0px auto 30px auto;
    text-align:center;
  `}
  ${media.between('768px', '1170px')`
    margin: 0px auto 20px auto
    text-align:center;
  `}
  ${media.greaterThan('1170px')`
    margin: 0px auto 20px auto
  `}
`

const Text = styled.div`
  font-size: 1em;
  font-weight: 600;
  text-align: justify;
  color: #114b62;

  ${media.lessThan('768px')`
  text-align: justify;
  `}

  ${media.between('768px', '1170px')`
    margin-top: 10px;
    height: 72px;
    font-size: 1em;
    max-width: 370px;
    text-align: justify;
  `}

  ${media.greaterThan('768px')`
    margin-top: 10px;
    height: 72px;
    font-size: 1em;
  `}

  ${media.greaterThan('1170px')`
    text-align: left;
    padding: 0;
    margin: 0;
  `}
`

const SectionIcn = styled.img`
  flex: 1;
  height: auto;
  width: 66px;
  object-fit: contain;
  margin-bottom: 15px;
  ${media.lessThan('1025px')`
    font-size: 1.4em;
    line-height: 22px;
    margin: 10px 10px 20px 10px;
    text-align:center;
  `}
`

const Img = styled.img`
  flex: 1;
  content: url('/images/section4.png');
  height: auto;
  width: 100%;
  max-height: 500px;
  object-fit: contain;

  ${media.lessThan('768px')`
    margin-top: 40px;
    max-height: 380px;
  `}

  ${media.between('768px', '1170px')`
    max-height: 530px;
    position: relative;
    top: 120px;
    margin-bottom: 120px;
  `}
`

const Blue = styled.span`
  color: ${theme.colors.primary};
`

export default Section4
