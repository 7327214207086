export const HitType = {
  1: 'appartement',
  2: 'maison',
  3: 'terrain',
  4: 'mixte',
  5: 'bureau',
  6: 'loft',
  7: 'viager',
  8: 'château',
  9: 'commerce',
  11: 'parking',
  12: 'ferme',
  13: 'bateau',
  14: 'immeuble',
  15: 'châlet',
  16: 'cave',
  17: 'annexe',
  18: 'constructible',
  19: 'bungalow',
  20: 'monastère',
  21: 'pentahouse',
  22: 'mobile-home',
  23: 'agricole',
  24: 'foret',
  25: 'industriel',
  26: 'vignoble'
}
