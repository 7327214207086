import React from 'react'
import styled from 'styled-components/macro'
import media from 'styled-media-query'

import IosBadgeUrl3 from 'assets/iosBadge/ios-badge-white@3x.png'
import IosBadgeUrl2 from 'assets/iosBadge/ios-badge-white@2x.png'
import IosBadgeUrl from 'assets/iosBadge/ios-badge-white.png'

import IosBadgeUrl3Black from 'assets/iosBadge/ios-badge@3x.png'
import IosBadgeUrl2Black from 'assets/iosBadge/ios-badge@2x.png'
import IosBadgeUrlBlack from 'assets/iosBadge/ios-badge.png'

/* Displayed on footer to display app on ios exists */
function IosBadge({ black }) {
  return (
    <A
      href="https://apps.apple.com/fr/app/paper-immobilier/id1466622175"
      target="_blank"
    >
      {black ? (
        <Img
          loading="lazy"
          src={IosBadgeUrlBlack}
          srcSet={`${IosBadgeUrl2Black} 2x, ${IosBadgeUrl3Black} 3x`}
          alt="ios-badge"
        />
      ) : (
        <Img
          loading="lazy"
          src={IosBadgeUrl}
          srcSet={`${IosBadgeUrl2} 2x, ${IosBadgeUrl3} 3x`}
          alt="ios-badge"
        />
      )}
    </A>
  )
}

const A = styled.a`
  display: flex;
  transition: all 0.2s ease;
  width: calc(100% - 20px);
  margin: 5px 10px;
  &:hover {
    cursor: pointer;
    transform: translateY(-8px);
    transform: scale(1.1);
  }
`

const Img = styled.img`
  width: 180px;
  object-fit: contain;
  &:hover {
    cursor: pointer;
  }

  ${media.lessThan('1025px')`
    width:100%;
    height: auto;
    max-height: 60px;
    margin: 5px auto;
  `}
`

export default IosBadge
