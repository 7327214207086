import React from 'react'
import styled from 'styled-components/macro'
import media from 'styled-media-query'
//import { NavLink } from 'react-router-dom'
import Intro from './Intro'
import Section1 from './Section1'
import Section2 from './Section2'
import Section3 from './Section3'
import Section4 from './Section4'
import Section5 from './Section5'
import Section6 from './Section6'
import Section7 from './Section7'
import Section8 from './Section8'

function Home() {
  // const BG = window.innerWidth <= 812 ? BGM : BGD

  return (
    <div>
      <HeaderContainer>
        <Intro />
      </HeaderContainer>
      <Center>
        <Path>
          {/* <PathBegin src={PathBeginUrl} alt="bg beginning" /> */}
          <Section1 />
          <Section2 />
          <Section3 />
          <Section4 />
          <Section5 />
          <Section6 />
          <Section7 />
          <Section8 />
        </Path>
      </Center>
    </div>
  )
}

const HeaderContainer = styled.div`
  background-size: contain;
  width: 100%;
  display: block;
  position: relative;
  height: auto;
  background: #ffffff;

  ${media.lessThan('768px')`
      background-size: contain;
      padding-bottom:0%;
    `}
  ${media.between('768px', '1440px')`
      background-size: contain;
      padding-bottom:0%;
    `}
`

const Center = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 0px;
  padding: 0;

  ${media.lessThan('1440px')`
    margin-top: 0px;
    padding: 0px;
  `}
  ${media.lessThan('768px')`
    margin-top: 0px;
    padding: 0 0px;
  `}
`
/*
const HeaderBackground = styled.img`
  width: 100%;
  object-fit: contain;

  ${media.greaterThan('1025px')`
     object-fit: cover;
  `}

  ${media.lessThan('812px')`
     width: auto;
     height: 380px;
  `}
`
*/
// const SHome = styled.div`
//   height: 770px;
//   background-image: url(${BG});
//   background-image: -webkit-image-set(url(${BG2}) 2x, url(${BG3}) 3x);
//   background-image: image-set(url(${BG2}) 1x, url(${BG3}) 2x);
//   background-repeat: no-repeat;
//   background-size: cover;
//   padding-top: 63px;
// `

// const PathBegin = styled.img`
//   position: relative;
//   top: -152px;
//   left: -62px;
//   /* ${media.lessThan('1025px')`
//     display: none;
//   `} */

//   ${media.between('319px', '1289px')`
//     display: none;
//   `}
// `
const Path = styled.div`
  width: 100%;

  ${media.lessThan('768px')`
    width: 100%;
    height: 100%;
    background-image: none;
  `}
  ${media.lessThan('1440px')`
    width: 100%;
    height: 100%;
    background-image: none;
  `}
  ${media.lessThan('1920px')`
  width: 100%;
  `}
  ${media.greaterThan('1920px')`
  width: 100%;

  `}
`
/*
const PaperPlane = styled.img`
  position: relative;
  top: 100px;
  left: 551px;

  ${media.lessThan('1289px')`
    display: none;
  `}
`
*/
export default Home
