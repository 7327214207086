import React, { useState } from 'react'
import styled, { css } from 'styled-components/macro'
import media from 'styled-media-query'
import theme from 'utils/theme'

// simple input (text or textarea)
function Input({
  label,
  type = 'text',
  disabled,
  activated,
  placeholder,
  name,
  value,
  onChange,
  rows = 4,
  error,
  required,
  show,
  showError
}) {
  const [topLabel, setTopLabel] = useState(
    !activated && value === '' ? false : true
  )
  const contextPlaceholder = topLabel ? placeholder : label
  const onClick = props => {
    setTopLabel(true)
  }

  const onBlur = () => {
    if (value === '' && !activated) {
      setTopLabel(false)
    }
  }

  const getInputColor = props => {
    // if (disabled) {
    //   return theme.colors.grey
    // }
    if (!value || value.length <= 0) {
      return theme.colors.brownGrey
    }

    if ((error || showError) && !value) {
      return theme.colors.scarlet
    }

    return error || showError
      ? theme.colors.scarlet
      : value && value.length > 0
      ? theme.colors.primary
      : theme.colors.brownGrey
  }

  const showRequired = !!required && !value

  /*{
  return (
    <Wrapper type={type}>
    {(topLabel || showRequired) }
    */
  return (
    <Wrapper type={type}>
      {topLabel && <Required>*</Required> && (
        <Label color={getInputColor()}>
          {label.replace('*', '')} {showRequired && <Required>*</Required>}
        </Label>
      )}

      {type !== 'textArea' ? (
        <SInput
          name={name}
          disabled={disabled}
          type={type}
          placeholder={contextPlaceholder}
          onChange={onChange}
          value={value}
          onClick={onClick}
          onBlur={onBlur}
          color={getInputColor()}
          showError={showError}
        />
      ) : (
        <STextArea
          name={name}
          disabled={disabled}
          placeholder={contextPlaceholder}
          onChange={onChange}
          value={value}
          rows={rows}
          onClick={onClick}
          onBlur={onBlur}
          color={getInputColor()}
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div.attrs(props => ({
  height: props.type !== 'textArea' ? 'inherit' : 'inherit'
}))`
  margin: 5px;
  position: relative;
  width: 100%;
  height: ${props => props.height};

  ${media.lessThan('1025px')`
    display: flex;
  `}
`
const Label = styled.div`
  position: absolute;
  top: -8px;
  left: 10px;
  padding: 0px 4px;
  font-size: 0.8em;
  color: ${props => props.color};
  //display: ${props => (props.show === 'true' ? 'block' : 'none')};
  background: #fff;
  z-index: 3;

  ${media.lessThan('1025px')`
    font-size: 11px;
    left: 20px;
  `}
`

const Required = styled.span`
  color: ${theme.colors.scarlet};
`

const inputStyle = css`
  width: 100%;
  height: 100%;
  min-height: 44px;
  box-sizing: border-box;
  border-radius: 10px;
  border: solid 1px ${props => props.color};

  font-size: 1em;
  color: #114b62;
  padding: 5px 10px;
  font-family: NunitoSans;
  -webkit-appearance: none;

  ::placeholder {
    color: #b4b4b4;
    font-family: NunitoSans;
  }
  :-ms-input-placeholder {
    color: #b4b4b4;
    font-family: NunitoSans;
  }
  ::-ms-input-placeholder {
    color: #b4b4b4;
    font-family: NunitoSans;
  }
  ${media.lessThan('1025px')`
    font-size: 1em;
    padding: 7px 9px;
    margin: auto 10px;
  `}
  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;
      background-color: white;
    `}
`

const SInput = styled.input`
  ${inputStyle}
  min-width: 250px;
  margin: 0;
`
/*
const SInputNoBorder = styled(SInput)`
  border: none;
`
*/
const STextArea = styled.textarea`
  ${inputStyle}
  resize: none;
  padding: 10px;
  width: 100%;
  top: 5px;
`
export default Input
