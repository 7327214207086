import { useState } from 'react'
import { getPhoneNumber } from 'utils/phone'
import { AsYouType } from 'libphonenumber-js'

// simple hook to manage input
export const useInput = initialValue => {
  const [value, setValue] = useState(initialValue)

  return {
    value,
    setValue,
    reset: () => setValue(''),
    onChange: event => {
      setValue(event.target.value)
    }
  }
}
// simple hook to manage number input
export const usePhoneInput = initialValue => {
  const [value, setValue] = useState({
    number: initialValue,
    error: null
  })

  return {
    value,
    setValue,
    reset: () =>
      setValue({
        number: '',
        error: null
      }),
    onChange: event => {
      let value = {}
      const phoneStr = event.target.value
      const phoneNumber = getPhoneNumber(phoneStr)
      if (phoneNumber) {
        value = { ...phoneNumber, error: null }
      } else {
        value = {
          number: new AsYouType('FR').input(phoneStr),
          error:
            phoneStr && phoneStr.length
              ? 'validation.invalid'
              : 'validation.required'
        }
      }
      setValue(value)
    }
  }
}
