import React from 'react'
import styled from 'styled-components/macro'
import media from 'styled-media-query'
import theme from 'utils/theme'
import Icn from 'assets/home/icn-creez.svg'
//import SectionTitle from 'components/sectionTitle'

// section of index page
function Section2() {
  return (
    <React.Fragment>
      <Row className="row">
        <Img />

        <Block>
          <SectionIcn src={Icn} />
          <Title>
            <Blue>Créez</Blue> l'alerte qui vous ressemble
          </Title>
          <Text>
            Envie d’une maison avec piscine ? Plutôt un appartement avec
            terrasse pour déjeuner l’été ?
            <br />
            <br />
            Avec plus de 30 critères de recherche, paramétrez votre alerte pour
            ne recevoir que les annonces qui vous intéressent.
          </Text>
        </Block>
      </Row>
    </React.Fragment>
  )
}

const Row = styled.div`
  position: relative;
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 1200px;
  margin: auto;
  padding: 60px 0;

  ${media.lessThan('1170px')`
    flex-direction: column-reverse;
    align-items: center;
    padding: 10px;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 50px;
    width: 90%;
  `}

  ${media.between('768px', '1170px')`
    margin-bottom: 60px;
  `}
`

const Block = styled.div`
  position: relative;
  max-width: 360px;
  width: 100%;
  padding-top: 100px;
  padding-right: 10%;
  flex: 2;

  ${media.lessThan('768px')`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto 5%;
    padding: 0;
    width: 90%;
  `}
  ${media.between('768px', '1170px')`
    max-width: 550px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto 5%;
    padding: 0;
  `}
  ${media.greaterThan('1170px')`

  `}
`

const Blue = styled.span`
  color: ${theme.colors.primary};
`

const SectionIcn = styled.img`
  flex: 1;
  height: auto;
  width: 68px;
  object-fit: contain;
  margin-bottom: 15px;
  ${media.lessThan('1025px')`
    font-size: 1.4em;
    line-height: 22px;
    margin: 10px 10px 20px 10px;
    text-align:center;
  `}
`

const Img = styled.img`
  flex: 1;
  content: url('/images/section2.png');
  height: auto;
  width: 100%;
  max-height: 550px;
  object-fit: contain;
  margin-top: 70px;

  ${media.lessThan('768px')`
    max-height: 390px;
    position: relative;
    margin-bottom: 20px;
    margin-top: 20px;
  `}

  ${media.between('768px', '1170px')`
    max-height: 530px;
  `}

  ${media.greaterThan('1170px')`
    margin-top: 0px;
    margin-bottom: 30px;
  `}

`

const Title = styled.div`
  font-size: 1.8em;
  font-weight: 800;
  color: ${theme.colors.secondary};
  margin-bottom: 30px;
  line-height: 1.1em;

  ${media.lessThan('768px')`
    font-size: 1.4em;
    line-height: 22px;
    margin: 10px auto 30px auto;
    text-align:center;
  `}
  ${media.between('768px', '1170px')`
    margin: 10px auto 20px auto;
    text-align:center;
  `}
  ${media.greaterThan('1170px')`
    margin: 10px auto 20px auto;
  `}

`

const Text = styled.div`
  font-size: 1em;
  font-weight: 600;
  text-align: justify;
  color: ${theme.colors.secondary};

  ${media.lessThan('768px')`
  text-align: justify;
  `}

  ${media.between('768px', '1170px')`
    margin-top: 10px;
    height: auto;
    font-size: 1em;
    max-width: 370px;
    text-align: justify;
  `}
`

export default Section2
