import React from 'react'
import styled from 'styled-components/macro'
import media from 'styled-media-query'
import theme from 'utils/theme'
//import BadgeBlock from '../broadcast/BadgeBlock'
//import SectionTitle from 'components/sectionTitle'
import Icn1 from 'assets/neuf/icn-recherche.svg'
import Icn2 from 'assets/neuf/icn-exclu.svg'
import Icn3 from 'assets/neuf/icn-catalogue.svg'

// section of index page
function Section1() {
  return (
    <Wrapper>
      <Title>
        Arlet, l'application idéale pour <Blue>acheter ou investir</Blue> dans
        le neuf
      </Title>

      <Row className="row">
        <Img />

        <Block>
          <TextContainer>
            <SectionIcn src={Icn1} />
            <Text>
              Recherchez et trouvez des logements anciens sur la même
              application
            </Text>
          </TextContainer>

          <TextContainer>
            <SectionIcn src={Icn2} />
            <Text>
              Recevez avant tout le monde les exclusivités pour profiter des
              meilleures opportunités
            </Text>
          </TextContainer>

          <TextContainer>
            <SectionIcn src={Icn3} />
            <Text>
              Un catalogue avec plus de 30 000 logements neufs sur toute la
              France
            </Text>
          </TextContainer>
        </Block>
      </Row>

      <Cta href="#">Je trouve le bien de mes rêves</Cta>
    </Wrapper>
  )
}

const Row = styled.div`
  position: relative;
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 1200px;
  margin: auto;
  padding: 60px 0;

  ${media.lessThan('1170px')`
    flex-direction: column;
    align-items: center;
    padding: 10px;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 50px;
    width: 90%;
  `}

  ${media.between('768px', '1170px')`
    margin-bottom: 60px;
  `}
`

const Block = styled.div`
  position: relative;
  max-width: 360px;
  width: 100%;
  padding-top: 100px;
  padding-right: 10%;
  flex: 2;

  ${media.lessThan('768px')`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto 5%;
    padding: 0;
    width: 90%;
  `}
  ${media.between('768px', '1170px')`
    max-width: 550px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto 5%;
    padding: 0;
  `}
  ${media.greaterThan('1170px')`

  `}
`

const Blue = styled.span`
  color: ${theme.colors.primary};
`

const SectionIcn = styled.img`
  flex: 1;
  height: auto;
  width: 68px;
  object-fit: contain;
  margin-bottom: 15px;
  margin: 0 30px 15px 0;

  ${media.lessThan('1025px')`
    font-size: 1.4em;
    line-height: 22px;
    margin: 40px 10px 10px 10px;
    text-align:center;
  `}
`

const Img = styled.img`
  flex: 1;
  content: url('/images/arlet-phone-neuf.png');
  height: auto;
  width: 100%;
  max-height: 550px;
  object-fit: contain;
  margin-top: 70px;

  ${media.lessThan('1026px')`
    max-height: 390px;
    position: relative;
    margin-bottom: 20px;
    margin-top: 20px;
    text-align: center;

  `}

  ${media.between('768px', '1170px')`
    max-height: 530px;
  `}

  ${media.greaterThan('1170px')`
    margin-top: 0px;
    margin-bottom: 30px;
  `}

`

const Title = styled.div`
  font-size: 1.8em;
  font-weight: 800;
  color: ${theme.colors.secondary};
  margin: 70px auto 10px auto;
  line-height: 1.1em;
  max-width: 640px;
  text-align: center;

  ${media.lessThan('768px')`
    font-size: 1.4em;
    line-height: 22px;
    text-align:center;
  `}
  ${media.between('768px', '1170px')`
    text-align:center;
  `}
  ${media.greaterThan('1170px')`
  `}

`

const TextContainer = styled.div`
  margin: 30px 0;
  display: flex;
  align-items: center;
  ${media.lessThan('1026px')`
    text-align: center;
    display: block;
  `}
`

const Text = styled.div`
  font-size: 1em;
  font-weight: 600;
  text-align: justify;
  color: ${theme.colors.secondary};

  ${media.lessThan('1026px')`
    text-align: center
  `}

  ${media.between('768px', '1170px')`
    margin-top: 10px;
    height: auto;
    font-size: 1em;
    max-width: 370px;
    text-align: justify;
  `}
`

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column !important;
  position: relative;
  background: #f3f9fd;
  margin: 0 auto;
  ${media.between('767px', '1025px')`
    flex-direction: row;
  `}
`
/*
const Section = styled.div`
  display: flex;
  justify-content: center;
  z-index: 0;
  position: relative;
  margin: 0 auto 50px auto;
  max-width: 800px;
  flex-wrap: wrap;
  justify-content: space-evenly;
  ${media.lessThan('1025px')`
    flex-direction: column;
    align-items: center;
    margin: 5px auto;
    padding-bottom: 30px;
  `}
`
*/
const Cta = styled.a`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  z-index: 998;
  width: 100%;
  max-width: 300px;
  height: 50px;
  background-color: #e84c5c;
  color: #ffffff;
  padding: 0 20px;
  font-weight: 700;
  margin: auto;
  text-align: center;
  cursor: pointer;
  border-radius: 40px;
  margin: 0 auto 60px;
`

export default Section1
