import React from 'react'
import styled from 'styled-components/macro'
import media from 'styled-media-query'
import theme from 'utils/theme'
import BannerBG from 'assets/home/banner-arlet-app.jpg'
//import logo from 'assets/logo/icon-app-arlet.png'
import arrow from 'assets/home/arrow-red.png'
import AndroidBadge from 'components/androidBadge'
import IosBadge from 'components/iosBadge'

// section of index page
function Section8() {
  return (
    <Section>
      <Center>
        <BG>
          <Container>
            <Text>
              Envie de trouver votre futur logement ? <br />
              Téléchargez l’application <Blue>Arlet</Blue> dès maintenant
            </Text>
            <ImgArrow src={arrow} alt="Téléchargez Arlet pour iOS ou Android" />
          </Container>

          <BadgesContainer style={{ marginBottom: 0 }}>
            <IosBadge />
            <AndroidBadge />
          </BadgesContainer>
        </BG>
      </Center>
    </Section>
  )
}

const Section = styled.div`
  ${media.lessThan('768px')`
    height: auto;
  `}
`

const Center = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${media.lessThan('768px')`
    height: auto;
    text-align: center;
    top: 0em;
    margin-bottom: 0em;
  `}
`

const Container = styled.div`
  text-align: center;
`

const Text = styled.div`
  position: relative;
  font-size: 1.8em;
  font-weight: 800;
  text-align: center;
  color: ${theme.colors.secondary};
  z-index: 0;
  height: auto;

  ${media.lessThan('768px')`
      margin-top: 11px;
      font-size: 1.4em;
      line-height: 1.2;
      padding: 0 20px;
    `}
    ${media.between('768px', '1170px')`
      margin: -41px ​auto 10px auto !important;
    `}
  ${media.greaterThan('1170px')`
        margin: 30px auto 10px auto;
      `}
`

const Blue = styled.span`
  color: ${theme.colors.primary};
`

const ImgArrow = styled.img`
  width: 70px;
  height: auto;
  position: relative;
  object-fit: contain;
  box-shadow: 0 0.2rem 0.4rem rgba (0, 0, 0, 0.05);
`

const BG = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 320px;
  background-image: url(${BannerBG});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 450px;
  position: relative;
  ${media.lessThan('768px')`
    padding: 0;
    height: 370px;
  `}

  ${media.between('769px', '1170px')`
    height: 340px;
    top: 8em;
    position: relative;
    margin-bottom: 8em;
  `}
`
/*
const SubText = styled.div`
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  font-size: 1em;
  font-weight: bold;
  line-height: 1.2;
  text-align: center;
  max-width: 545px;

  ${media.lessThan('1025px')`
  font-size: .9em;
  `}
`
*/
const BadgesContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;

  ${media.lessThan('768px')`
    flex-direction: row;
  `}
`

export default Section8
