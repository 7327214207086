import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'
import { changePassword } from 'services/user'
import * as validators from 'utils/validators'
import * as qs from 'utils/qs'
import { useToasts } from 'react-toast-notifications'
import Icon from 'components/icon'

import { useInput } from 'hooks/input.hooks'
import Input from 'components/input'
import Button from 'components/button'
/*
 * Form to update password (not used)
 */
function ContactForm({ textAreaRows }) {
  const [disabled, setDisabled] = useState(false)

  const uid = qs.get('uid') || null

  const { value: email, onChange: onChangeEmail, reset: resetEmail } = useInput(
    qs.get('email') || ''
  )

  const [showPassword, setShowPassword] = useState(false)
  const togglePassword = useCallback(() => {
    setShowPassword(!showPassword)
  }, [showPassword])

  const {
    value: password,
    onChange: onChangePassword,
    reset: resetPassword
  } = useInput('')

  const { addToast } = useToasts()
  useEffect(() => {
    if (
      !validators.isEmailValid(email) ||
      !validators.isPasswordValid(password)
    ) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }, [password, email])

  /* useCallback please */
  const send = async () => {
    const data = {
      password,
      email,
      uid
    }

    try {
      await changePassword(data)
      addToast('Votre mot de passe a été modifié', {
        appearance: 'success',
        autoDismiss: true
      })
      // resetEmail()
      resetPassword()
    } catch (err) {
      console.error(err)
      addToast('Oups, une erreur est survenue ! Veuillez réessayer', {
        appearance: 'error',
        autoDismiss: true
      })
    }
  }

  return (
    <React.Fragment>
      <FormCenter style={{ width: '100%' }}>
        <Input
          label="Email *"
          placeholder="Ex: jm.dupont@gmail.com"
          name="email"
          disabled={true}
          activated={true}
          required={true}
          value={email}
          error={email && !validators.isEmailValid(email)}
          onChange={onChangeEmail}
        />
      </FormCenter>

      <FormCenter style={{ width: '100%' }}>
        <Input
          label="Mot de passe *"
          placeholder="******"
          name="password"
          type={showPassword ? 'text' : 'password'}
          activated={true}
          required={true}
          value={password}
          error={email && !validators.isPasswordValid(password)}
          onChange={onChangePassword}
        />
        <InputRightButton onClick={togglePassword}>
          <Icon name={showPassword ? 'hide' : 'show'} color={'#000'} />
        </InputRightButton>
      </FormCenter>

      <FormCenter style={{ margin: 'auto' }}>
        <Button disabled={disabled} onClick={send}>
          Envoyer
        </Button>
      </FormCenter>
    </React.Fragment>
  )
}

const Center = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`

const InputRightButton = styled.button`
  background: transparent;
  position: absolute;
  right: 0px;
  top: 0px;
  bottom: 0px;
  padding-right: 10px;
  ${media.lessThan('1025px')`
  padding-right: 20px;
  `}
  cursor: pointer;
`

const FormCenter = styled(Center)`
  margin-bottom: 34px;
  &:last-child {
    margin-bottom: 0;
  }
  ${media.lessThan('1025px')`
  margin-bottom: 25px;
    align-items: center;
    width: 100%;
  `}

  ${media.greaterThan('1025px')`
    width: 50%;
  `}
`

export default ContactForm
