import React from 'react'
import styled from 'styled-components'
import theme from 'utils/theme'
import logo3 from 'assets/logo/paper@3x.png'
import logo2 from 'assets/logo/paper@2x.png'
import logo from 'assets/logo/paper.png'
import AndroidBadge from 'components/androidBadge'
import IosBadge from 'components/iosBadge'

// view to tell user that mobile app needs to be updated
function DesktopView() {
  return (
    <DesktopContainer>
      <Logo
        size={105}
        src={logo}
        srcSet={`${logo2} 2x, ${logo3} 3x`}
        alt="Logo Arlet"
      />
      <Title>
        Oops ! Cette page n’est accessible que depuis l’application.{' '}
      </Title>
      <TextDesktop>
        Ouvrez cette page depuis votre mobile ou téléchargez l’application pour
        accéder à toutes les fonctionnalités.
      </TextDesktop>
      <div>
        <SIosBadge>
          <IosBadge black={true} />
        </SIosBadge>
        <AndroidBadge black={true} />
      </div>
    </DesktopContainer>
  )
}

function MobileView() {
  return (
    <MobileContainer>
      <Logo src={logo} srcSet={`${logo2} 2x, ${logo3} 3x`} alt="Paper logo" />
      <TitleMobile>Mise à jour de l'application requise</TitleMobile>
      <TextMobile>
        Pour votre confort et pour plus de sécurité, nous avons fait évoluer la
        gestion des mots de passe. Mettez à jour l'application pour modifier ou
        récupérer votre mot de passe.
      </TextMobile>
      <MobileApp>
        <IosBadge black={true} />
        <AndroidBadge black={true} />
      </MobileApp>
    </MobileContainer>
  )
}

function getOS() {
  const userAgent = navigator.userAgent.toLowerCase()
  if (userAgent.indexOf('android') > -1) {
    return 'android'
  } else if (userAgent.indexOf('iphone') > -1) {
    return 'ios'
  }
  return 'desktop'
}

function ShouldUpdate() {
  let OS = getOS()

  return OS === 'desktop' ? (
    <Page>
      <DesktopView />
    </Page>
  ) : (
    <MobileView />
  )
}

export default ShouldUpdate

const Page = styled.div`
  padding: 100px 0px;
  color: ${theme.colors.black};
`

const TitleMobile = styled.p`
  font-size: 16px;
  font-weight: 900;
  color: #114b62;
  text-align: center;
`

const TextMobile = styled.div`
  text-align: justify;
  font-size: 15px;
  font-weight: 600;
  margin: 25px 0;
`

const DesktopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 200px;
`

const MobileContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100vh - 72px - 75px);
  position: absolute;
  top: 72px;
  left: 0;
  right: 0;
  color: ${theme.colors.secondary};
  font-size: 18px;
  font-weight: bold;
  line-height: 1.28;
  padding: 0 25px;
`

const MobileApp = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Logo = styled.img`
  width: ${props => props.size || '75'}px;
  height: ${props => props.size || '75'}px;
  margin: 0 auto;
`

const Title = styled.p`
  font-size: 30px;
  font-weight: 900;
  color: #114b62;
  text-align: center;
`

const TextDesktop = styled.p`
  font-size: 23px;
  font-weight: 600;
  color: #114b62;
  line-height: 1.22;
  text-align: center;
`

const SIosBadge = styled.span`
  margin-right: 25px;
`
