import axios from 'axios'

// supposed ot change password but not used for the moment
export const changePassword = async body => {
  try {
    const res = await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}/user/change-password`,
      body
    )
    return res.data
  } catch (err) {
    throw err
  }
}
