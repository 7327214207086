export const ExtraOptionsType = {
  GROUND_FLOOR: -4,
  NO_WORK: -5
}

export const OptionsType = {
  DUPLEX: 1,
  ELEVATOR: 2,
  SEA: 3,
  MOUTAIN: 4,
  LAKE: 5,
  SKI_RUN: 6,
  TRIPLEX: 7,
  REDEMPTION_GUARANTEE: 8,
  CARPARK: 9,
  BOX: 10,
  GARAGE: 11,
  CELLAR: 12,
  ATTIC: 13,
  CARETAKER: 14,
  INTERCOM: 15,
  ENTRYCODE: 16,
  VIDEOPHONE: 17,
  POOL: 18,
  SERVICES: 19,
  DISABLEDACCESS: 20,
  FURNISHED: 21,
  FIREPLACE: 22,
  GARDEN: 23,
  PRIVATE_GARDEN: 24,
  BALCONY: 25,
  LOGGIA: 26,
  PATIO: 27,
  STOREROOM: 28,
  LAUNDRY: 29,
  OPENVIEW: 30,
  ALARM: 31,
  SINGLE_STORY: 32,
  SEPARATE_RESTROOM: 33,
  AIR_CONDITIONING: 34,
  PET_NOT_ALLOWED: 35,
  OPTIONWOODPERGOLA: 36,
  OPTIONELECTRICROLLERSHUTTER: 37,
  OPTIONTERRACE: 38,
  OPTIONTENNIS: 39,
  OPTIONFRONTGATE: 40,
  LAST_FLOOR: 41,
  OPTIONHALL: 42,
  OPTIONHIGHSPEEDINTERNET: 43,
  PARQUET: 44,
  OPTIONUNOBSCURED: 45,
  OPTIONBASEMENT: 46,
  OPTIONDININGROOM: 47,
  OPTIONLIVINGROOM: 48,
  OPTIONDOUCHE: 49,
  OPTIONBAIGNOIRE: 50,
  OPTIONGOLF: 51,
  OPTIONJACUZZI: 52,
  OPTIONSAUNA: 53,
  OPTIONSPA: 54,
  OPTIONHAMMAM: 55,
  OPTIONSPORT: 56,
  OPTIONCAVEAVIN: 57,
  OPTIONCOLLOC: 58,
  OPTIONMEZZANINE: 59,
  OPTIONTERRAIN: 60,
  OPTIONSILENCIEUX: 61
}
//way to display options
export const firstOptions = [
  {
    label: 'Balcon / Terrasse',
    value: `${OptionsType.OPTIONTERRACE} OR ${OptionsType.BALCONY}`,
    icon: 'am_balcon'
  },
  { label: 'Cave / Cellier', value: `${OptionsType.CELLAR}`, icon: 'am_cave' },
  {
    label: 'Jardin',
    value: `${OptionsType.GARDEN} OR ${OptionsType.PRIVATE_GARDEN}`,
    icon: 'am_jardin'
  },
  {
    label: 'Loggia / Patio',
    value: `${OptionsType.LOGGIA} OR ${OptionsType.PATIO}`,
    icon: 'am_patio'
  },
  {
    label: 'Parking / Box',
    value: `${OptionsType.CARPARK} OR ${OptionsType.BOX} OR ${OptionsType.GARAGE}`,
    icon: 'am_parking'
  },
  {
    label: 'Salle à manger',
    value: `${OptionsType.OPTIONDININGROOM}`,
    icon: 'am_manger'
  },
  {
    label: 'Salle de bain (baignoire)',
    value: `${OptionsType.OPTIONBAIGNOIRE}`,
    icon: 'am_sdb'
  },
  {
    label: "Salle d'eau (douche)",
    value: `${OptionsType.OPTIONDOUCHE}`,
    icon: 'am_douche'
  },
  {
    label: 'Toilettes séparées',
    value: `${OptionsType.SEPARATE_RESTROOM}`,
    icon: 'am_toilettes'
  }
]
//way to display options
export const secondOptions = [
  {
    label: 'Ascenseur',
    value: `${OptionsType.ELEVATOR}`,
    icon: 'am_ascenseur'
  },
  {
    label: 'Rez-de-chaussée',
    value: `${ExtraOptionsType.GROUND_FLOOR}`,
    icon: 'am_rdc'
  },
  {
    label: 'Dernier étage',
    value: `${OptionsType.LAST_FLOOR}`,
    icon: 'am_dernier'
  },
  { label: 'Parquet', value: `${OptionsType.PARQUET}`, icon: 'am_parquet' },
  { label: 'Meublé', value: `${OptionsType.FURNISHED}`, icon: 'am_meuble' },
  { label: 'Cheminée', value: `${OptionsType.FIREPLACE}`, icon: 'am_cheminee' },
  { label: 'Piscine', value: `${OptionsType.POOL}`, icon: 'am_piscine' },
  {
    label: 'Accès PMR',
    value: `${OptionsType.DISABLEDACCESS}`,
    icon: 'am_pmr'
  },
  {
    label: 'Sans travaux',
    value: `${ExtraOptionsType.NO_WORK}`,
    icon: 'am_travaux2'
  }
]
