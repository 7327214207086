import React from 'react'
import styled from 'styled-components/macro'
import media from 'styled-media-query'
import theme from 'utils/theme'

import { euro } from 'utils'

// section to display title price of a real estate
function Title({ title, price, priceLabel, category }) {
  return (
    <STitle>
      <Name>{title}</Name>
      {typeof price !== 'undefined' && (
        <Price>
          {priceLabel && <MinPriceLabel>{priceLabel} </MinPriceLabel>}
          <div>
            {euro(price)} {category === 2 && <span>/ mois</span>}
          </div>
        </Price>
      )}
    </STitle>
  )
}

const STitle = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.lessThan('1025px')`
    flex-direction: column;
    align-items: flex-start;
  `}
`

const Name = styled.div`
  font-size: 1.5em;
  font-weight: 800;
  color: #114b62;

  ${media.lessThan('1025px')`
    font-size: 1.2em;
  `}
`

const MinPriceLabel = styled.div`
  font-size: 16px;
  margin-right: 10px;
  line-height: 22px;
`

const Price = styled.div`
  font-size: 30px;
  line-height: 30px;
  font-weight: 700;
  text-align: right;
  color: ${theme.colors.primary};
  min-width: 205px;

  ${media.lessThan('1025px')`
    font-size: 1.3em;
    text-align: left;
    & > * {
      display: inline;
      font-size: 16px;
      margin: 0px;
    }
  `}
`

export default Title
