import React from 'react'
import styled from 'styled-components/macro'
import media from 'styled-media-query'
import theme from 'utils/theme'
import Icn from 'assets/home/icn-recevez.svg'
import Notification from 'assets/home/notification.png'

// section of index page
function Section3() {
  return (
    <SectionContainer>
      <End>
        <Block>
          <SectionIcn src={Icn} />
          <Title>
            <Blue>Recevez</Blue> vos annonces où vous le souhaitez
          </Title>

          <Text>
            Choisissez la fréquence à laquelle vous recevrez vos annonces selon
            votre rythme de recherche.
            <br />
            <br />
            Vous recherchez activement ? Optez pour une répcetion en temps réel
            par notification push.
            <br />
            <br />
            Vous n’êtes pas pressé ou juste curieux ? Vous pouvez également
            recevoir les nouvelles annonces sur votre boite email 1 fois par
            jour / semaine.
          </Text>
        </Block>
        <Img loading="lazy" src={Notification} />
      </End>
    </SectionContainer>
  )
}

const End = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  max-width: 1200px;
  margin: auto;

  ${media.lessThan('1170px')`
    flex-direction: column !important;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    margin-bottom: 0;
    width: 90%;
  `}


  ${media.greaterThan('1170px')`
    flex-direction: row;
    justify-content: center;
    align-items: center;
  `}

  ${media.lessThan('1440px')`
    padding: 10px 0;
  `}

`

const SectionContainer = styled.div`
  background-color: ${theme.colors.light_primary};
  width: 100%;
  padding: 60px 0;

  ${media.lessThan('1025px')`
    padding: 50px 5% 0 5%;
    box-sizing: border-box;
  `}
`

const Block = styled.div`
  position: relative;
  max-width: 360px;
  width: 100%;
  padding-top: 40px;
  padding-left: 10%;
  flex: 2;

  ${media.lessThan('768px')`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto 5%;
    padding: 0;
  `}
  ${media.between('768px', '1170px')`
    max-width: 550px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto 5%;
    padding: 0;
  `}
  ${media.greaterThan('1170px')`
    display: flex;
    flex-direction: column;
    margin-bottom: 150px;
  `}
`

const SectionIcn = styled.img`
  flex: 1;
  height: auto;
  width: 55px;
  object-fit: contain;
  margin-bottom: 15px;
  ${media.lessThan('1440px')`
    font-size: 1.4em;
    line-height: 22px;
    margin: 10px 10px 20px 10px;
    text-align:center;
  `}
`

const Img = styled.img`
  flex: 1;
  content: url('/images/section3.png');
  height: auto;
  width: 100%;
  max-height: 319px;
  object-fit: contain;
  padding: 60px 0;

  ${media.between('768px', '1170px')`
    top: 120px;
    position: relative;
    margin-bottom: 120px;
  `}
`

const Title = styled.div`
  font-size: 1.8em;
  font-weight: 800;
  color: ${theme.colors.secondary};
  margin-bottom: 30px;
  line-height: 1.1em;

  ${media.lessThan('768px')`
    font-size: 1.4em;
    line-height: 22px;
    margin: 10px auto 30px auto;
    text-align:center;
  `}
  ${media.between('768px', '1170px')`
    margin: 10px auto 20px auto;
    text-align:center;
  `}
  ${media.greaterThan('1170px')`
    margin: 10px auto 20px auto;
  `}
`

const Text = styled.div`
  font-size: 1em;
  font-weight: 600;
  text-align: justify;
  color: #114b62;

  ${media.lessThan('768px')`
  text-align: justify;
  `}

  ${media.between('768px', '1170px')`
    margin-top: 10px;
    height: 72px;
    font-size: 1em;
    max-width: 370px;
    text-align: justify;
  `}

  ${media.greaterThan('768px')`
    margin-top: 10px;
    height: 72px;
    font-size: 1em;
  `}

  ${media.greaterThan('1170px')`
    text-align: left;
    padding: 0;
    margin: 10px auto 20px auto;
  `}

`

const Blue = styled.span`
  color: ${theme.colors.primary};
`

export default Section3
